<template>
  <div class="contanter_vipUser">
    <!-- 头部 -->
    <clone-tab></clone-tab>
    <!-- -->

    <div class="content_cipUser">
      <div class="vipUser_message" v-if="options.status == 1">工会会费是“中华全国总工会”的规定缴纳的会费，是工会组织开展各项活动所需要的费用，工会会员会费：188元/年。
      </div>
      <div class="vipUser_message" v-else>劳务合同是利道工会会员与利道工会有限公司签署的劳动协议，签署后为我司临时员工。</div>
      <div class="vipUser_time" v-if="options.status == 1">合约截止日期：{{ userFrom.union_expire_at }}</div>
      <div class="vipUser_time" v-else>合约截止日期：{{ userFrom.union_shareholder_expire_at }}</div>
      <div class="vipUser_warn"><img src="../../../assets/Slice31@2x.png" />到期后，您的账号将无法享受权益</div>
      <div class="user_info_vip">您当前的会员信息：</div>
      <div class="user_from" v-if="isVip">
        <div>姓名：{{ userFrom.union_info.username }}</div>
        <div>性别：{{ userFrom.union_info.sex }}</div>
        <div>联系方式：{{ userFrom.union_info.phone }}</div>
        <div>身份账号：{{ userFrom.union_info.identity }}</div>
        <div v-if="options.status == 1">地址信息：{{ userFrom.union_info.address }}</div>
        <div class="detail" @click="detailBtn">修改资料</div>
      </div>
      <!-- 续约步骤 -->
      <step-view :status="options.status"></step-view>
      <!-- 须知 -->
      <notice :status="options.status"></notice>
    </div>
    <div class="renew" @click="renewBtn">立即续费</div>

    <!-- 修改信息 -->
    <change-information :status="options.status" @success="success" ref="changeInformation" v-if="fromShow"
      @cloneBtn="cloneBtn"></change-information>

    <!-- 申请书 -->
    <application-form :userFrom="userFrom" :status="options.status" :dataTime="dataTime" :dataTime1="dataTime1"
      :imgToken="imgToken" :nameImg="nameImg" v-if="renewShow" :renewShow="renewShow" @ruleImgUblod="ruleImgUblod"
      @cloneBtn="cloneBtn" @confim="confim"></application-form>

    <!-- 签名 -->
    <!-- 签字弹窗 -->
    <van-dialog v-model="signtureShow" title="标题" show-cancel-button>
      <div class="signtureShow" @click="signtureShow">
        <div class="signtureShow_box">
          <catSignature :imgWidth="imgWidth" @signatureBtn="signatureBtn" @cloneBtn="cloneBtn1" :imgToken="imgToken"
            @cloneImg="cloneImg" @imgUblod="imgUblod"></catSignature>
        </div>
      </div>
    </van-dialog>

  </div>
</template>
<script>
import cloneTab from "../../../components/expenditure/cloneTab";
import stepView from "../../../components/expenditure/stepView";
import notice from "../../../components/expenditure/notice";
import changeInformation from "../../../components/expenditure/changeInformation";
import applicationForm from "../../../components/expenditure/applicationForm";
import catSignature from "../../../components/catSignature.vue";
import { Toast } from "vant";
// 数据
import * as apiCommon from "@/api/common";
export default {
  data() {
    return {
      fromShow: false, // 修改信息
      renewShow: false, // 申請書
      signtureShow: false, //签名
      imgToken: "", //上传图片token
      imgWidth: 645, //签名宽度
      isVip: true, //判断是续费会员还是股东
      userFrom: {
        union_info: {
          bank: {}
        }
      },
      dataFrom: {
        userName: { label: "姓名", placeholder: "请输入姓名", value: "" },
        card: { label: "身份证号", placeholder: "请输入身份证号", value: "" },
        phone: { label: "联系方式", placeholder: "请输入手机号", value: "" },
        address: { label: "地址", placeholder: "请输入地址", value: "" },
        sex: {
          list: ["女", "男"],
          value: "",
          title: "性别:",
          text: "点击选择性别",
          boxWidth: "width:230px"
        }
      },
      captchaConfig: {
        title: "获取验证码",
        mobile: "",
        type: 6,
        code: "",
        imgToken: "",
        lineShow: true,
        userinfo: true
      },
      nameImg: "",
      options: null,
      dataTime: '',
      dataTime1: '',
    };
  },
  components: {
    cloneTab,
    stepView,
    notice,
    changeInformation,
    applicationForm,
    catSignature
  },
  created() {
    document.title = "授权中心";
    this.getToken();
    this.getUserInfo();
    this.options = this.$route.query;
    this.getdateNoTime(new Date().getTime())
  },
  methods: {
    getdateNoTime(date) {
      var now = new Date(parseInt(date)),
        y = now.getFullYear(),
        m = now.getMonth() + 1,
        d = now.getDate();
      this.dataTime = y + "-" + (m < 10 ? "0" + m : m) + "-" + (d < 10 ? "0" + d : d);
      this.dataTime1 = y + 3 + "-" + (m < 10 ? "0" + m : m) + "-" + (d < 10 ? "0" + d : d);
    },

    success() {
      console.log(1);
      this.fromShow = false;
      Toast({
        message: "操作成功",
        icon: "success",
        duration: 1000
      });
      this.getUserInfo();
    },
    parentMsg() {
      console.log(213123123);
    },
    // 获取用户信息
    getUserInfo() {
      apiCommon.getuser_api().then(res => {
        console.log(res, "res");
        if (res.code == 0) {
          this.userFrom = res.data;

          console.log(this.dataFrom, "data");
        }
      });
    },
    // 修改信息
    detailBtn() {
      this.fromShow = true;
      console.log(this, "12", this.$refs.changeInformation);

      this.$nextTick(() => {
        this.$refs.changeInformation.childFun(this.userFrom);
      });
    },
    cloneBtn() {
      this.fromShow = false;
      this.renewShow = false;
    },
    // 续费
    renewBtn() {
      this.renewShow = true;
    },
    // 确认申请书 ,签名
    confim(index) {
      this.renewShow = false;
      console.log(index, "22");
      if (index == 0) {
        this.signtureShow = false;
        Toast({
          message: "签名成功",
          icon: "success",
          duration: 1000
        });
      } else {
        this.signtureShow = true;
      }
    },
    // 签名------------------------------------------------
    cloneBtn1() {
      this.signtureShow = false;
    },
    async getToken() {
      console.log(2);
      let res = await apiCommon.getImgToken();
      if (!res || typeof res === "string" || res.error)
        return this.$toast.fail(res ? res.error || res : "获取七牛云信息失败!");
      this.imgToken = res.data;
    },
    signatureBtn() {
      this.toastShow = true;
      this.configData.title = "签名不能为空";
      setTimeout(e => {
        console.log(e);
        this.toastShow = false;
      }, 1000);
    },
    imgUblod(img) {
      this.signtureShow = false;
      this.nameImg = img;
      this.toastShow = true;
      this.configData.title = "签名完成";
      setTimeout(() => {
        this.toastShow = false;
      }, 1000);
      this.popShow = true;
    },
    ruleImgUblod(img) {
      console.log(img);
    }
  }
};
</script>
<style>
.contanter_vipUser {
  width: 100%;
  /* height: 100%; */
  background-color: #000;
  padding-top: 20px;
  padding-bottom: 130px;
  box-sizing: border-box;
  /* background-image: url(../../../assets/Slice51.png); */
  background-size: 100% 100%;
  position: relative;
}

.content_cipUser {
  width: 636px;
  margin: 40px auto 0px;
  font-size: 30px;
}

.vipUser_message {
  color: #e3c084;
  letter-spacing: 2px;
  font-weight: 300;
  line-height: 50px;
}

.vipUser_time {
  color: #fff;
  margin: 30px 0;
}

.vipUser_warn {
  display: flex;
  color: #e3c084;
  height: 30px;
  vertical-align: middle;
  margin-bottom: 30px;
}

.vipUser_warn img {
  width: 26px;
  height: 26px;
  margin-right: 10px;
}

.user_info_vip {
  color: #fff;
}

.user_from {
  width: 636px;
  border-radius: 15px 15px 15px 15px;
  opacity: 1;
  border: 2px solid #e3c084;
  margin: 30px 0;
  padding: 32px 40px;
  box-sizing: border-box;
  font-size: 30px;
  color: #e3c084;
}

.user_from div {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(214, 176, 111, 0.3);
}

.user_from .detail {
  width: 190px;
  height: 60px;
  background: #d6b06f;
  border-radius: 50px 50px 50px 50px;
  opacity: 1;
  text-align: center;
  line-height: 60px;
  font-size: 30px;
  color: #fff;
  margin: 0px auto;
  box-sizing: border-box;
}

.renew {
  position: fixed;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  width: 489px;
  height: 80px;
  background: #d6b06f;
  box-shadow: inset 0px 4px 5px 0px rgba(255, 255, 255, 0.3);
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  text-align: center;
  line-height: 80px;
  font-size: 30px;
  color: #fff;
}

.signtureShow {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
}

.signtureShow_box {
  width: 90%;
  height: 80%;
  background: linear-gradient(180deg, #fed3a8 0%, #f6ebda 100%);
  margin: 150px auto 0;
  border-radius: 15px;
  padding-bottom: 20px;
}

.bg1 {
  background: linear-gradient(180deg, #f1b056 0%, #ec6f37 100%);
  border: none;
  color: #fff;
}

.bg2 {
  background: linear-gradient(180deg, #dfc495 0%, #d6b06f 100%);
  border: none;
  color: #fff;
}

.bg3 {
  background: linear-gradient(180deg, #dfc495 0%, #d6b06f 100%);
}

.van-picker__cancel,
.van-picker__confirm {
  font-size: 30px;
}
</style>